export const searchFilters = [
  {
    type: 'searchBy',
    label: 'Nombre Comercial',
    placeholder: 'Nombre Comercial',
    name: 'client__tradename',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Pedido',
    placeholder: 'Código de Pedido',
    name: 'code',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Contacto',
    placeholder: 'Nombre de Contacto',
    name: 'search_destination',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Cliente',
    placeholder: 'Nombre de cliente',
    name: 'client_full_name',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Email de Cliente',
    placeholder: 'Email de Cliente',
    name: 'client__user__email',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Transportista',
    placeholder: 'Nombre de Transportista',
    name: 'courier_full_name',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Código de Referencia',
    placeholder: 'Código de Referencia',
    name: 'memo',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Miembro',
    placeholder: 'Cliente miembro asociado',
    name: 'user_full_name',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Email de Miembro',
    placeholder: 'Email de miembro asociado',
    name: 'user__email',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Memo de Ruta',
    placeholder: 'Memo de Ruta',
    name: 'batch_memo',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Guía de Remisión',
    placeholder: 'Código de Guía',
    name: 'shipping_document',
    defaultValue: ''
  },
  {
    type: 'searchBy',
    label: 'Operación',
    placeholder: 'Nombre de Operación',
    name: 'operation',
    defaultValue: ''
  }
]

export default [
  {
    type: 'select',
    label: 'Tipo de pedido',
    title: 'Todos',
    options: {
      multiple: true
    },
    name: 'type',
    elements: []
  },
  {
    type: 'select',
    options: {
      multiple: true
    },
    name: 'status',
    label: 'Estado del pedido',
    title: 'Todos',
    elements: [
      { value: 'created', label: 'Creado', selected: false },
      { value: 'searching', label: 'Buscando', selected: false },
      { value: 'accepted', label: 'Aceptado', selected: false },
      { value: 'in_progress', label: 'En progreso', selected: false },
      { value: 'ended', label: 'Finalizado', selected: false },
      { value: 'cancelled', label: 'Cancelado', selected: false },
      { value: 'completed', label: 'Completado', selected: false },
      {
        value: 'partial_completed',
        label: 'Completado parcial',
        selected: false
      },
      { value: 'failed', label: 'Fallido', selected: false },
      { value: 'no_courier', label: 'No courier', selected: false }
    ]
  },
  {
    type: 'select',
    options: {
      multiple: true
    },
    name: 'payment_status',
    label: `Estado del pago`,
    title: 'Todos',
    elements: [
      { value: 'not_required', label: 'No aplica', selected: false },
      { value: 'pending', label: 'Pendiente', selected: false },
      { value: 'confirmed', label: 'Confirmado', selected: false },
      { value: 'failed', label: 'Fallido', selected: false }
    ]
  },
  {
    type: 'toggle',
    name: 'is_programmed',
    label: 'Programados',
    propertyName: 'is_programmed',
    defaultValue: false
  },
  // Modificar 'is_duplicate' a 'is_multipoint'
  {
    type: 'toggle',
    name: 'is_multipoint',
    label: 'Multipuntos',
    propertyName: 'is_multipoint',
    defaultValue: false
  },
  {
    type: 'date',
    label: 'Fecha de creación',
    options: {
      multiple: true
    },
    name: 'created_at',
    propertyName: 'created_at',
    defaultValue: ''
  },
  {
    type: 'date',
    label: 'Fecha de programación',
    options: {
      multiple: true
    },
    name: 'programmed_date',
    propertyName: 'programmed_date',
    defaultValue: ''
  },
  ...searchFilters
]
